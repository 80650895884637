<template>
  <div :class="releaseClass" :id="`tito-ticket-${release.slug}`">
    <div class="tito-release-description">
      <label :for="inputId" class="tito-release--title">
        {{ title }}
        <small v-if="ticketsRemainingCount">
          ({{ ticketsRemainingCount }})
        </small>
        <small
          v-if="release.super_combo_releases"
          class="tito-release--combo-label"
          >Combo</small
        >
      </label>
      <p
        class="tito-release-description--degressive-prices-description"
        v-if="degressivePricesDescription"
        v-html="degressivePricesDescription"
      />
      <div
        class="tito-release-description--description"
        v-if="descriptionMarkdown"
        v-html="descriptionMarkdown"
      />
      <div
        v-if="release.super_combo_releases"
        class="tito-release-description--combos"
      >
        <i>{{ comboIncludesHeading }}</i>
        <ul>
          <li v-for="superComboRelease in release.super_combo_releases">
            <span v-if="superComboRelease.quantity > 1">
              {{ superComboRelease.quantity }} &times;
            </span>
            {{ translate(superComboRelease, 'title') }}
          </li>
        </ul>
      </div>
    </div>
    <div class="tito-details--waiting-list" v-if="waitingList">
      <div class="tito-quantity">
        <a
          class="tito-choose-waiting-list-button"
          @click.prevent="joinWaitingList(release)"
        >
          {{ $t("views.events._join_waiting_list.button_label", locale) }}
        </a>
      </div>
    </div>
    <div
      class="tito-price-details-donation"
      v-else-if="release.pricing_type == 'donation'"
    >
      <span
        class="tito-release--donation-currency"
        v-if="release.pricing_type == 'donation'"
      >
        {{ event.currency_symbol }}
      </span>
      <input
        v-if="release.pricing_type == 'donation'"
        type="number"
        step=".01"
        class="tito-release--donation-input"
        autocomplete="off"
        v-model="donation"
        :placeholder="donationPrice"
        :disabled="!available"
      />
      <span class="tito-release--tax" v-if="release.tax_description">
        {{ release.tax_description }}
      </span>
    </div>
    <div class="tito-price-details-regular" v-else>
      <div v-if="discounted">
        <span class="tito-discounted-from">
          <strike v-if="release.pricing_type != 'donation'">
            {{ priceFormatted }}
          </strike>
        </span>
        <span
          class="tito-discounted-price"
          v-html="priceWithDiscountFormatted"
          v-if="release.pricing_type != 'donation'"
        ></span>
      </div>
      <template v-if="!discounted && release.price_html">
        <span
          class="tito-price"
          v-html="priceFormatted"
          v-if="release.pricing_type != 'donation'"
        ></span>
      </template>
      <span class="tito-release--tax" v-if="release.tax_description">
        {{ release.tax_description }}
      </span>
    </div>
    <div
      class="tito-times"
      v-if="
        available && release.price_html && release.max_tickets_per_person != 1
      "
    >
      &times;
    </div>
    <quantity-field
      ref="quantityField"
      v-if="available"
      :release="release"
      :input-id="inputId"
      :disabled="disabled"
      :solo="solo"
      v-model="quantity"
    ></quantity-field>
    <span class="tito-release--status" v-if="!available && !waitingList">
      {{ status_description }}
      <span v-if="upcoming">{{ release.start_at_formatted }}</span>
    </span>
  </div>
</template>

<script>
import { formatMoney } from "accounting";
import QuantityField from "./QuantityField.vue";
import translate from "js/src/mixins/translate.js";

export default {
  mixins: [translate],

  props: [
    "accountId",
    "disabled",
    "discountCode",
    "event",
    "eventId",
    "locale",
    "release",
    "solo",
  ],

  components: { QuantityField },

  watch: {
    value: {
      handler() {
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
  created() {
    this.$emit("input", this.value);
  },
  data() {
    let prefilledQuantity = null;
    if (this.$store.state.initialQuery[this.release.slug]) {
      const queryQuantity = parseInt(
        this.$store.state.initialQuery[this.release.slug]
      );
      prefilledQuantity = queryQuantity;
    }

    if (!prefilledQuantity && this.release.display_state === "available") {
      if (this.release.default_quantity) {
        prefilledQuantity = this.release.default_quantity;
      } else if (this.solo) {
        prefilledQuantity = 1;
      }
    }

    const prefilledDonation = this.release.suggested_donation
      ? formatMoney(this.release.suggested_donation, { symbol: "" })
      : null;

    return {
      donation: prefilledDonation,
      quantity: prefilledQuantity,
    };
  },

  computed: {
    available() {
      return this.release.display_state === "available";
    },

    waitingList() {
      return this.release.display_state == "waiting_list";
    },

    upcoming() {
      return this.release.display_state == "upcoming";
    },

    expired() {
      return this.release.display_state == "expired";
    },

    soldOut() {
      return this.release.display_state == "sold_out";
    },

    locked() {
      return this.release.display_state == "locked";
    },

    offSale() {
      return this.release.display_state == "off_sale";
    },

    comboIncludesHeading() {
      if (this.release.only_issue_combos) {
        return this.$t(
          "views.events._supercombo_description.ticket_includes_text",
          this.locale
        );
      } else {
        return this.$t(
          "views.events._supercombo_description.ticket_also_includes_text",
          this.locale
        );
      }
    },

    degressivePriceIndex() {
      if (
        !Number.isInteger(parseInt(this.quantity)) ||
        parseInt(this.quantity) <= 0
      ) {
        return 0;
      } else if (this.quantity > this.release.degressive_prices.length) {
        return this.release.degressive_prices.length - 1;
      } else {
        return this.quantity - 1;
      }
    },

    degressivePricesDescription() {
      if (this.degressivePricing) {
        if (this.discounted) {
          return this.translate(
            this.discountReleasePrices[this.release.id],
            "degressive_prices_description"
          );
        } else {
          return this.translate(this.release, "degressive_prices_description");
        }
      }
    },

    degressivePricing() {
      return !!this.release.degressive_prices;
    },

    description() {
      return this.translate(this.release, "description");
    },
    descriptionMarkdown() {
      if (this.description) {
        return this.$options.filters.markdown(this.description);
      }
    },
    value() {
      if (!this.release) {
        return {};
      }
      let quantity = "";
      if (this.quantity === true) {
        quantity = 1;
      } else if (this.quantity === false) {
        quantity = "";
      } else {
        quantity = this.quantity;
      }
      const out = {
        release_id: this.release.id,
        quantity: quantity,
      };
      if (this.release.pricing_type === "donation") {
        out["donation"] = this.donation;
      }
      return out;
    },

    discounted() {
      return (
        this.release.price &&
        this.discountReleasePrices &&
        this.release.id in this.discountReleasePrices &&
        this.priceFormatted != this.priceWithDiscountFormatted
      );
    },

    discountReleasePrices() {
      if (
        !this.event.discount_codes ||
        !this.event.discount_codes[this.discountCode.toLowerCase()]
      )
        return;
      return this.event.discount_codes[this.discountCode.toLowerCase()]
        .release_prices;
    },

    donationPrice() {
      if (this.release.min_donation) {
        return formatMoney(this.release.min_donation, { symbol: "" });
      } else {
        return formatMoney(this.price_with_discount, { symbol: "" });
      }
    },
    inputName() {
      return `release-${this.release.slug}`;
    },
    inputId() {
      return `release-${this.release.slug}-${this._uid}`;
    },

    priceFormatted() {
      if (this.degressivePricing) {
        return this.translate(this.release, "degressive_prices")[
          this.degressivePriceIndex
        ];
      } else {
        return this.translate(this.release, "price_html");
      }
    },

    priceWithDiscountFormatted() {
      if (this.discountReleasePrices) {
        if (this.degressivePricing) {
          return this.translate(
            this.discountReleasePrices[this.release.id],
            "degressive_prices"
          )[this.degressivePriceIndex];
        } else {
          return this.translate(
            this.discountReleasePrices[this.release.id],
            "price_html"
          );
        }
      }
    },

    releaseClass() {
      return {
        "tito-release": true,
        "tito-release--expired": this.expired,
        "tito-release--sold-out": this.soldOut,
        "tito-release--upcoming": this.upcoming,
        "tito-release--locked": this.locked,
        "tito-release--waitlisted": this.waitingList,
        "tito-release--donation": this.pricing_type == "donation",
      };
    },

    status_description() {
      if (!this.available) {
        return this.$t(
          `decorators.release.status.${this.release.display_state}`,
          this.locale
        );
      }
    },

    ticketsRemainingCount() {
      if (this.release.allocatable_count) {
        return this.$tc(
          "decorators.release.tickets_remaining_indicator",
          this.release.allocatable_count,
          { count: this.release.allocatable_count }
        );
      }
    },

    title() {
      return this.translate(this.release, "title");
    },
  },

  methods: {
    joinWaitingList(release) {
      this.$parent.processOrPost("waitlisted_person.new", {
        release: release,
        accountId: this.accountId,
        eventId: this.eventId,
        locale: this.locale,
      });
    },

    reset() {
      if (this.$refs.quantityField) {
        this.$refs.quantityField.reset();
      }
    },
  },
};
</script>
