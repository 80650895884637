import axios from "axios";
import messages from "js/src/locales/en.json";
import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});

const loadedLanguages = ["en"];

function setI18nLanguage(locale) {
  i18n.locale = locale;
  axios.defaults.headers.common["Accept-Language"] = locale;
  document.querySelector("html").setAttribute("locale", locale);
  return locale;
}

export async function loadLanguageAsync(locale) {
  if (i18n.locale !== locale) {
    if (!loadedLanguages.includes(locale)) {
      const messages = await import(
        /* webpackChunkName: "full-locale-[request]" */ `js/src/locales/${locale}.json`
      );
      i18n.setLocaleMessage(locale, messages[locale]);
      loadedLanguages.push(locale);
      return setI18nLanguage(locale);
    }
    return setI18nLanguage(locale);
  }
  return locale;
}
