<template>
  <div class="tito-locked-ticket-message">
    <p>{{ $t("views.registrations.locked_ticket_warning", locale) }}</p>
  </div>
</template>

<script>
export default {
  props: ["locale"],
};
</script>
