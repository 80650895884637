<template>
  <a :href="ticketUrl(slug)" target="_blank" v-if="stopBecauseInsecure">View ticket</a>
</template>

<script>
import { i18n, loadLanguageAsync } from "js/src/config/i18n.js";
import hostedUrls from "js/src/mixins/hostedUrls.js";
import routeIfNotCurrent from "js/src/mixins/routeIfNotCurrent.js";
import sslCheck from "js/src/mixins/sslCheck.js";
import router from "js/src/config/router.js";
import store from "js/src/store/index.js";

export default {
  i18n,
  mixins: [hostedUrls, routeIfNotCurrent, sslCheck],
  name: "TitoTicket",
  router,
  store,
  props: {
    locale: null,
    path: null,
    slug: null,
  },

  mounted() {
    if (this.stopBecauseInsecure) {
      return;
    }
    this.setClosingDisabled();
    this.getTicket();
  },

  computed: {
    accountId() {
      if(this.ticket && this.ticket.account) {
        return this.ticket.account.slug;
      }
    },

    defaultLocale() {
      if(this.ticket) {
        return this.ticket.locale;
      }
    },

    eventId() {
      if(this.ticket && this.ticket.event) {
        return this.ticket.event.slug;
      }
    },

    localeOrDefault() {
      return this.locale || this.defaultLocale;
    },

    routeName() {
      switch(this.path) {
        case "edit" : return "ticketEdit";
        case "reassign" : return "ticketReassign";
        default: return "ticket";
      }
    },

    ticket() {
      const storeTicket = this.$store.getters["ticket/find"](this.slug)
      if(storeTicket && storeTicket.$state) {
        return storeTicket.$state.attributes;
      }
    }
  },

  methods: {
    getTicket() {
      this.$store.dispatch("ticket/get", this.slug);
    },

    setClosingDisabled() {
      this.$store.commit("setClosingDisabled", this.$el.parentElement.hasAttribute("closing-disabled"));
    }
  },

  watch: {
    localeOrDefault() {
      if(this.localeOrDefault) {
        loadLanguageAsync(this.localeOrDefault);
      }
    },

    ticket() {
      this.routeIfNotCurrent({
        name: this.routeName,
        params: {
          accountId: this.accountId,
          eventId: this.eventId,
          ticketId: this.slug,
          locale: this.locale
        },
      });
    }
  }
};
</script>
