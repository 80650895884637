import http from "./http.js";
import Base from "./base.js";
import providerHandler from "./paymentProviders/handler.js";
class TitoEvent extends Base {
  static get className() {
    return "Event";
  }

  get identifier() {
    return this.path;
  }

  async createWaitlistedPerson(params) {
    let response = await http.post(`${this.itemPath}/waitlisted_people`, {
      waitlisted_person: params
    });
    return response.data.waitlisted_person;
  }

  loadCreditCardPaymentProvider() {
    if (!this.credit_card_payment_option) {
      return Promise.reject("Skip: credit card payment");
    }
    const ccHandler = providerHandler(this.credit_card_payment_option);
    switch (this.credit_card_payment_option.gateway_type) {
      case "stripe":
        return import(`./paymentProviders/stripe.js`).then(ccHandler);
        break;
    }
  }

  loadPaypalPaymentProvider() {
    if (
      !this.paypal_payment_option ||
      this.paypal_payment_option.gateway_type != "paypal_platform"
    ) {
      return Promise.reject("Skip: PayPal payment");
    }
    const ppHandler = providerHandler(
      Object.assign({ currency: this.currency }, this.paypal_payment_option)
    );
    return import(`./paymentProviders/paypal.js`).then(ppHandler);
  }

  async loadPaymentProviders() {
    this.loadCreditCardPaymentProvider();
    this.loadPaypalPaymentProvider();
  }

  static get validations() {
    return {
      title: { presence: true }
    };
  }

  static itemPath(id) {
    return `/${id}`;
  }
}
export default TitoEvent;
