import router from "js/src/config/router.js";
import store from "js/src/store/index.js";
import uniq from "lodash/uniq";
import { i18n, loadLanguageAsync } from "js/src/config/i18n.js";

export default {
  i18n,
  router,
  store,
  props: {
    buttonLabel: null,
    discountCode: {
      default() {
        return (
          new URLSearchParams(window.location.search).get("discount_code") || this.$route.query.discount_code
        );
      },
      type: String,
    },
    emailPlaceholder: {
      default: "eve@example.com",
    },
    event: null,
    inline: {
      default() {
        return !!window.tito.config.inline;
      },
    },
    insideIframeId: {
      default: null,
    },
    namePlaceholder: {
      default: "Eve Moneypenny",
    },
    releases: {
      default() {
        return (
          new URLSearchParams(window.location.search).get("releases") || this.$route.query.releases
        );
      },
    },
    rsvp: {
      default: null,
    },
    locale: {
      default() {
        return (
          new URLSearchParams(window.location.search).get("locale") || this.$route.params.locale || this.$route.query.locale
        );
      },
    },
    source: {
      default() {
        return (
          new URLSearchParams(window.location.search).get("source") || this.$route.query.source
        );
      },
    },
    mergedPrefill: Object,
    prefillName: {
      default: null,
    },
    prefillEmail: {
      default: null,
    },
    prefillCompanyName: {
      default: null,
    },
    prefillVatNumber: {
      default: null,
    },
    prefillPhoneNumber: {
      default: null,
    },
    prefillAddress: {
      default: null,
    },
    prefillCity: {
      default: null,
    },
    prefillStateProvinceRegion: {
      default: null,
    },
    prefillZipPostalCode: {
      default: null,
    },
    prefillCountry: {
      default: null,
    },
    prefillMetadata: {
      default: null,
    },
  },
  computed: {
    accountId() {
      return this.event.split("/")[0];
    },

    eventId() {
      return this.event.split("/")[1];
    },

    singleEventApiRequestNotOnePerWidget() {
      return this.inline && this.isHostFrame;
    },

    configuration() {
      return {
        emailPlaceholder: this.emailPlaceholder,
        namePlaceholder: this.namePlaceholder,
        hostUrl: this.currentUrlWithoutRouteHash,
      };
    },

    configuredWidgetsCount() {
      return Object.keys(this.$store.state.configuration).length;
    },

    currentUrlWithoutRouteHash() {
      return document.location.href.replace(document.location.hash, "");
    },

    isHostFrame() {
      return !document.location.href.includes("/iframes/");
    },

    isNewRegistrationRouteAndWidgetConfigurationAlreadyStored() {
      return (
        this.$route.name == "newRegistration" && this.configuredWidgetsCount > 0
      );
    },

    isTitoButtonOrTitoWidgetComponent() {
      return ["TitoButton", "TitoWidget"].includes(this.$options.name);
    },

    routeHashQueryStringParameters() {
      return new URLSearchParams(this.routeHashQueryString);
    },

    routeHashQueryString() {
      if (document.location.hash.includes("?"))
        return document.location.hash.substring(
          document.location.hash.indexOf("?")
        );
    },

    standardQueryStringParameters() {
      return new URLSearchParams(window.location.search);
    },
  },

  data() {
    return {
      widgetId: null,
    };
  },

  async created() {
    if (
      this.isHostFrame &&
      this.isTitoButtonOrTitoWidgetComponent &&
      !this.isNewRegistrationRouteAndWidgetConfigurationAlreadyStored
    )
      this.storeWidgetConfiguration();

    if (this.discountCode) {
      this.discountCode = this.discountCode.trim();
      this.discountCodeToApply = this.discountCode;
    }

    this.get();
    if (this.locale) {
      loadLanguageAsync(this.locale);
    }
  },

  methods: {
    allWidgetElements() {
      return document.querySelectorAll("tito-button, tito-widget");
    },

    applyDiscountCodeFromRsvpIfPresent(discountCodes) {
      if(this.rsvp && !this.discountCodeToApply && discountCodes) {
        Object.entries(discountCodes).forEach(([discountCodeString, discountCodeObject]) => {
          if(discountCodeObject.rsvp == this.rsvp) {
            this.discountCodeToApply = discountCodeString;
          }
        });
      }
    },

    discountCodesFromAttributesOnCurrentEventWidgets() {
      let discountCodes = [];
      this.allWidgetElements().forEach(
        function (element) {
          if (element.getAttribute("event") == this.event)
            discountCodes.push(element.getAttribute("discount-code"));
        }.bind(this)
      );
      if (discountCodes.length > 0) return discountCodes.join(",");
    },

    discountCodeFromStandardQueryString() {
      return this.standardQueryStringParameters.get("discount_code") || "";
    },

    discountCodeFromRouteHashQueryString() {
      return this.routeHashQueryStringParameters.get("discount_code") || "";
    },

    discountCodesFromQueryStringsOrAttributesOnCurrentEventWidgets() {
      return uniq(
        [
          this.discountCodeFromStandardQueryString(),
          this.discountCodeFromRouteHashQueryString(),
          this.discountCodesFromAttributesOnCurrentEventWidgets(),
        ]
          .join(",")
          .split(",")
      ).join(",");
    },

    eventApiRequestDiscountCodes() {
      if (
        this.singleEventApiRequestNotOnePerWidget &&
        !this.discountCodeApplying
      ) {
        const commaDelimitedString =
          this.discountCodesFromQueryStringsOrAttributesOnCurrentEventWidgets() ||
          "";
        const list = commaDelimitedString.split(",");
        if (
          this.discountCodeToApply &&
          !list.includes(this.discountCodeToApply)
        ) {
          list.push(this.discountCodeToApply);
        }
        return list.join(",");
      } else {
        return this.discountCodeToApply;
      }
    },

    eventApiRequestReleases() {
      if (this.singleEventApiRequestNotOnePerWidget) {
        return this.releasesFromQueryStringsOrAttributesOnCurrentEventWidgets();
      } else {
        return this.releases;
      }
    },

    get() {
      if (!this.inline) {
        return;
      }

      const eventParams = {
        event: this.event,
        discountCodes: this.eventApiRequestDiscountCodes(),
        releases: this.eventApiRequestReleases(),
        rsvp: this.rsvp,
        source: this.source,
      };

      window.tito("event.get", eventParams, (titoEvent) => {
        this.titoEvent = titoEvent;
        this.applyDiscountCodeFromRsvpIfPresent(titoEvent.discount_codes);
        this.discountCodeApplying = false;
        this.discountApplied = true;
        if (!this.locale && titoEvent.default_locale != "en") {
          loadLanguageAsync(titoEvent.default_locale);
        }
      });
    },

    releasesFromAttributesOnCurrentEventWidgets() {
      let releases = "";

      this.allWidgetElements().forEach(
        function (element) {
          if (
            element.getAttribute("event") == this.event &&
            element.getAttribute("releases")
          )
            releases += "," + element.getAttribute("releases");
        }.bind(this)
      );

      return releases;
    },

    releasesFromStandardQueryString() {
      return this.standardQueryStringParameters.get("releases") || "";
    },

    releasesFromRouteHashQueryString() {
      return this.routeHashQueryStringParameters.get("releases") || "";
    },

    releasesFromQueryStringsOrAttributesOnCurrentEventWidgets() {
      return uniq(
        [
          this.releasesFromStandardQueryString(),
          this.releasesFromRouteHashQueryString(),
          this.releasesFromAttributesOnCurrentEventWidgets(),
        ]
          .join(",")
          .split(",")
      ).join(",");
    },

    storeWidgetConfiguration() {
      this.widgetId = this.configuredWidgetsCount;
      this.$store.commit("addConfiguration", {
        configuration: this.configuration,
        widgetId: this.widgetId,
      });
    },
  },
};
