  <template>
  <div class="tito-events">
    <template v-if="events">
      <div class="tito-events--upcoming">
        <div v-for="event in events.upcoming" class="tito-event">
          <a :href="event.url"  class="tito-event--event-link">
            <img class="tito-event--banner" :src="event.banner_url" v-if="event.banner_url" />
            <div class="tito-event--details">
              <h2 class="tito-event--title">{{ event.title }}</h2>
              <div class="tito-event--time">{{ event.time }}</div>
              <div class="tito-event--location" v-if="event.location">{{ event.location }}</div>
            </div>
          </a>
          <div class="tito-event--admin-link">
            <a :href="event.admin_url" v-if="event.admin_url">Admin</a>
          </div>
        </div>
      </div>

      <div class="tito-events--unscheduled">
        <div v-for="event in events.unscheduled" class="tito-event">
          <a :href="event.url" class="tito-event--event-link">
            <img class="tito-event--banner" :src="event.banner_url" v-if="event.banner_url" />
            <div class="tito-event--details">
              <h2 class="tito-event--title">{{ event.title }}</h2>
              <div class="tito-event--time">{{ event.time }}</div>
              <div class="tito-event--location" v-if="event.location">{{ event.location }}</div>
            </div>
          </a>
          <div class="tito-event--admin-link">
            <a :href="event.admin_url" v-if="event.admin_url">Admin</a>
          </div>
        </div>
      </div>

      <h3 v-if="events.past.length">Past events</h3>
      <div class="tito-events--past">
        <div v-for="event in events.past" class="tito-event" :style="{ 'background-image': 'url(' + event.banner_url + ')' }">
          <a :href="event.url" class="tito-event--event-link">
            <div class="tito-event--details">
              <h2 class="tito-event--title">{{ event.title }}</h2>
              <div class="tito-event--location" v-if="event.location">
                {{ event.location }}
              </div>
              <div class="tito-event--time">
                {{ event.time }}
              </div>
            </div>
          </a>
          <div class="tito-event--admin-link">
            <a :href="event.admin_url" v-if="event.admin_url">Admin</a>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import router from "js/src/config/router.js";
import routeIfNotCurrent from "js/src/mixins/routeIfNotCurrent.js";

export default {
  router,
  mixins: [routeIfNotCurrent],
  name: "TitoEvents",
  props: {
    account: null,
  },

  data() {
    return {
      events: null
    }
  },

  mounted() {
    this.getEvents();
  },

  computed: {
  },

  methods: {
    async getEvents() {
      const response = await axios.get(
        `https://${window.tito.config.checkout_host}/${this.account}.json`
      )
      this.events = response.data.events;
    },
  }
};
</script>
