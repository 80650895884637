var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tito-widget"},[(_vm.titoEvent)?_c('form',{staticClass:"tito-widget-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.displayReleases.length > 0 && !_vm.titoEvent.registration_unavailable)?_c('div',[_vm._l((_vm.displayReleases),function(release){return _c('tito-release',{key:release.id,ref:"release",refInFor:true,attrs:{"discount-code":_vm.discountCodeToApply,"release":release,"account-id":_vm.accountId,"event-id":_vm.eventId,"event":_vm.titoEvent,"locale":_vm.localeToUse,"solo":_vm.displayReleases.length == 1,"disabled":_vm.submitting},model:{value:(_vm.quantities[release.id]),callback:function ($$v) {_vm.$set(_vm.quantities, release.id, $$v)},expression:"quantities[release.id]"}})}),_vm._v(" "),(_vm.anyReleasesLocked)?_c('locked-warning-message',{attrs:{"locale":_vm.localeToUse}}):_vm._e(),_vm._v(" "),(_vm.anyReleasesAllocatable)?_c('div',{staticClass:"tito-form-actions",class:{
          'tito-form-actions--with-discount':
            _vm.titoEvent.settings && _vm.titoEvent.settings.show_discount_code_field,
        }},[(_vm.showDiscountCodeField)?_c('div',{class:{
            'tito-discount': true,
            'tito-discount--applying': !_vm.readyToSubmit && !_vm.discountApplied,
          }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.discountCodeToApply),expression:"discountCodeToApply"}],class:{
              'tito-discount-code-field': true,
              'tito-invalid': !!_vm.discountCodeWarning,
            },attrs:{"type":"text","disabled":_vm.discountCodeApplying,"placeholder":_vm.$t(
                'views.registrations._discount_code_field.discount_code_placeholder',
                this.localeToUse
              )},domProps:{"value":(_vm.discountCodeToApply)},on:{"blur":_vm.applyDiscount,"input":function($event){if($event.target.composing)return;_vm.discountCodeToApply=$event.target.value}}}),_vm._v(" "),(_vm.discountCodeWarning)?_c('span',{staticClass:"tito-discount--warning"},[_vm._v(_vm._s(_vm.discountCodeWarning))]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('button',{staticClass:"tito-submit",attrs:{"type":"submit","disabled":_vm.disabled}},[(_vm.submitting)?[_c('font-awesome-icon',{attrs:{"icon":['far', 'spinner'],"spin":""}})]:[_vm._v("\n            "+_vm._s(_vm.translate(_vm.titoEvent, "register_button_label", {
                skipDefault: true,
              }) ||
              _vm.$t(
                "decorators.registration.continue_button_label.continue",
                _vm.locale
              ))+"\n          ")]],2):(!_vm.discountApplied)?_c('button',{staticClass:"tito-submit tito-submit--discount-code",attrs:{"type":"submit","disabled":_vm.disabled}},[(_vm.discountCodeApplying)?[_c('font-awesome-icon',{attrs:{"icon":['far', 'spinner'],"spin":""}})]:[_vm._v("\n            "+_vm._s(_vm.$t(
                "views.registrations._discount_code_field.discount_code_submit_label",
                _vm.localeToUse
              ))+"\n          ")]],2):_vm._e()]):_vm._e()],2):_c('div',{staticClass:"tito-widget-registration-unavailable",domProps:{"innerHTML":_vm._s(_vm.translate(_vm.titoEvent, 'registration_unavailable_message'))}})]):_c('div',{staticClass:"tito-widget-loading"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'spinner'],"spin":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }