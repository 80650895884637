import developmentMode from "js/src/tito/plugins/development_mode.js";
import hits from "js/src/tito/plugins/hits.js";
import testMode from "js/src/tito/plugins/test_mode.js";

const syncPlugins = {
  development_mode: developmentMode,
  hits: hits,
  test_mode: testMode,
};

export default async function (item) {
  if (syncPlugins[item[1]]) {
    syncPlugins[item[1]](item[2]);
  } else {
    var script = document.createElement("script");
    script.src = `https://${window.tito.config.plugins_host}/${item[1]}.js`;
    script.defer = true;
    script.onerror = function () {
      console.warn(
        "There was an error loading the",
        `'${item[1]}'`,
        "plugin. Check our docs:",
        "https://ti.to/docs/api/widget"
      );
    };
    document.body.appendChild(script);
  }
}
