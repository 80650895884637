export default function (params) {
  console.debug("[Tito][hits]", "plugin loaded");

  const urls = [];

  tito("on:widget:loaded", function (data) {
    if (data) {
      const url = data.$state.attributes.tracking_pixel_url;
      if (url) {
        if (urls.indexOf(url) == -1) {
          const src = document.getElementsByTagName("body")[0];
          const img = document.createElement("img");
          img.src = url;
          img.alt = "";
          img.role = "presentation";
          src.appendChild(img);
          urls.push(url);
          console.debug("[Tito][hits]", "Fire tracking for", data.path);
        }
      }
    }
  });
}
