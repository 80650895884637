if (process.env.NODE_ENV === "production") {
  console.groupCollapsed("[Tito]");
} else {
  console.group("[Tito]");
}
// No idea why this is necessary
__webpack_public_path__ = `https://${window.tito.config.js_host}/packs/`;
console.log("served by dashboard using", window.tito.config.js_host);

import "js/src/config/fontawesome.js";

import "js/src/tito/index.js";
import setup from "js/src/config/setup.js";

import Vue from "vue";

import vueCustomElement from "vue-custom-element";
Vue.use(vueCustomElement);

import TitoButton from "js/src/components/widget/TitoButton.vue";
import TitoEvents from "js/src/components/widget/TitoEvents.vue";
import TitoWidget from "js/src/components/widget/TitoWidget.vue";
import TitoRegisterInterest from "js/src/components/TitoRegisterInterest.vue";
import TitoRegistration from "js/src/components/TitoRegistration.vue";
import TitoTicket from "js/src/components/TitoTicket.vue";

Vue.customElement("tito-button", TitoButton);
Vue.customElement("tito-events", TitoEvents);
Vue.customElement("tito-register-interest", TitoRegisterInterest);
Vue.customElement("tito-registration", TitoRegistration);
Vue.customElement("tito-ticket", TitoTicket);
Vue.customElement("tito-widget", TitoWidget);

import "js/css/widget.scss";
import "js/css/checkout.scss";

Vue.config.productionTip = false;

import Tito from "js/src/client/client.js";
console.log("config is", window.tito.config);
if (window.tito.config && typeof window.tito.config.key !== "undefined") {
  console.log("setting api key to", window.tito.config.key);
  Tito.api_key = window.tito.config.key;
}

Tito.baseURL = `https://${window.tito.config.checkout_host}`;

if (tito.config && (!tito.config.iframe || tito.config.overlay)) {
  setup();
}
console.groupEnd();
