<template>
  <inline-widget
    v-bind="$props"
    :merged-prefill="calculatedMergedPrefill"
    v-if="inline"
  />
  <iframe-widget
    v-bind="$props"
    :merged-prefill="calculatedMergedPrefill"
    v-else
  />
</template>

<script>
import base from "js/src/mixins/base.js";
import prefillMerge from "js/src/mixins/prefillMerge.js";
import IframeWidget from "js/src/components/widget/IframeWidget.vue";
import InlineWidget from "js/src/components/widget/InlineWidget.vue";

export default {
  mixins: [base, prefillMerge],
  name: "TitoWidget",

  created() {
    if (typeof this.inline === "undefined" && tito.widget.config.inline) {
      this.inline = true;
    }
    if (this.insideIframeId) {
      return;
    }
    if (this.inline) {
      console.log("[Tito]", "Loading inline");
    } else {
      console.log("[Tito]", "Loading in an iframe");
    }
  },

  components: { IframeWidget, InlineWidget },
};
</script>
