export default {
  methods: {
    routeIfNotCurrent(route) {
      if (!route) {
        return;
      }
      try {
        // Preserve original query params if present
        if (Object.keys(this.$store.state.initialQuery).length > 0) {
          if(typeof(route) == "object") {
            if(typeof(route.query) == "object") {
              route.query = {...this.$store.state.initialQuery, ...route.query };
            } else {
              route.query = this.$store.state.initialQuery;
            }
          }
        }

        const toPath = this.$router.resolve(route).route.fullPath;
        const fromPath = this.$route.fullPath;
        if (route && toPath != fromPath) {
          this.$router.replace(route);
        }
      } catch (e) {
        console.warn("[Tito]", `Error caught while routing to ${route}`, e);
      }
    },
  },
};
