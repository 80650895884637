<template>
  <a :href="eventBaseUrl" target="_blank" v-if="stopBecauseInsecure">Click here to register interest</a>
  <form class="tito-register-interest-form" @submit.stop.prevent="submit" v-else>
    <div class="tito-register-interest-success" v-if="success">
      <font-awesome-icon :icon="['fas', 'check']" fixed-width />
      {{ $t("views.events.register_interest.thank_you", locale, { name: (name || email) }) }}
    </div>
    <template v-else>
      <div class="tito-register-interest-form--name">
        <label v-if="fields.includes('name')" class="tito-name-label">
          <font-awesome-icon :icon="['fas', 'user']" />
          {{ $t("decorators.registration.name_field_placeholder", locale) }}
          <input
            type="text"
            class="tito-register-interest-name-field"
            v-model="name"
            :placeholder="namePlaceholder"
          />
        </label>
      </div>

      <div class="tito-register-interest-form--email">
        <label class="tito-email-label">
          <font-awesome-icon :icon="['fas', 'envelope']" />
          {{
            $t(
              "views.registrations._form.billing_details.email_placeholder",
              locale
            )
          }}
          *
          <input
            type="email"
            class="tito-register-interest-email-field"
            v-model="email"
            v-validation="'email'"
            :placeholder="emailPlaceholder"
            :required="true"
          />
        </label>
        <span class="tito-error" v-if="errors.email">
          {{ errors.email.join(", ") }}
        </span>
      </div>
      <button type="submit">
        <font-awesome-icon :icon="['far', 'spinner']" spin v-if="loading" />
        <template v-else>{{ buttonLabelOrDefault }}</template>
      </button>
    </template>
  </form>
</template>

<script>
import axios from "axios";
import router from "js/src/config/router.js";
import { i18n, loadLanguageAsync } from "js/src/config/i18n.js";
import hostedUrls from "js/src/mixins/hostedUrls.js";
import routeIfNotCurrent from "js/src/mixins/routeIfNotCurrent.js";
import sslCheck from "js/src/mixins/sslCheck.js";
import validation from "js/src/directives/validation.js";

export default {
  i18n,
  router,
  directives: { validation },
  mixins: [hostedUrls, routeIfNotCurrent, sslCheck],
  name: "TitoRegisterInterest",

  props: {
    buttonLabel: null,
    emailPlaceholder: {
      default: "eve@example.com",
    },
    event: null,
    fields: {
      default() {
        return ["name"];
      },
    },
    locale: {
      default() {
        return this.$route.params.locale || this.$route.query.locale;
      },
    },
    namePlaceholder: {
      default: "Eve Moneypenny",
    },
  },

  data() {
    return {
      name: null,
      email: null,
      errors: {},
      loading: false,
      success: false,
    };
  },

  computed: {
    buttonLabelOrDefault() {
      if(this.buttonLabel) {
        return this.buttonLabel;
      } else {
        return this.$t(
          "views.events.register_interest.button_label",
          this.locale
        )
      }
    }
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        const response = await axios.post(
          `https://${window.tito.config.checkout_host}/${this.event}/interested_users.json`,
          {
            interested_user: {
              email: this.email,
              name: this.name,
            },
            locale: this.locale
          }
        );
        this.loading = false;
        this.success = true;
      } catch (e) {
        this.loading = false;
        if (e.response.status === 422) {
          console.log("Error!", e.response);
          this.errors = e.response.data["errors"];
        }
      }
    },
  },
};
</script>

<style scoped>
  .tito-register-interest-success svg {
    color: #00ceaf;
  }
</style>
