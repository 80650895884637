export default {
  computed: {
    insecureProtocol() {
      return document.location.protocol === "http:";
    },

    developmentMode() {
      return !!tito.config.developmentMode;
    },

    stopBecauseInsecure() {
      if (this.insecureProtocol) {
        if (this.developmentMode) {
          console.warn(
            "[Tito]",
            "[SSL Check 🔒]",
            "Host website is using an insecure protocol but proceeding anyway because development_mode is enabled."
          );
          return false;
        } else {
          console.error(
            "[Tito]",
            "[SSL Check 🔒]",
            "Showing link to Tito because host website is using an insecure protocol."
          );
          return true;
        }
      } else {
          console.log(
            "[Tito]",
            "[SSL Check 🔒]",
            "Host website is using a secure protocol 👍"
          );
        return false;
      }
    },
  },
};
