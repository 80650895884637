import { isEmpty, isEmail } from "validator";

const validateEvent = new CustomEvent("validate");

export default {
  inserted: function (el, binding) {
    const validate = function (options) {
      let invalid = false;
      delete el.dataset.invalid;
      if (isEmpty(el.value) && !!options["allowBlank"]) {
        el.classList.remove("tito-invalid");
        el.classList.remove("tito-valid");
        delete el.dataset.invalid;
        return;
      }
      if (
        el.hasAttribute("required") ||
        (binding.value && binding.value.required)
      ) {
        if (isEmpty(el.value)) {
          invalid = true;
          el.dataset.invalid = true;
        }
      }
      if (
        binding.value &&
        (binding.value == "email" || (binding.value && binding.value.email))
      ) {
        if (!isEmail(el.value)) {
          invalid = true;
          el.dataset.invalid = true;
        }
      }
      if (invalid) {
        el.dataset.invalid = true;
        el.classList.add("tito-invalid");
        el.classList.remove("tito-valid");
        if (el.labels && el.labels[0]) {
          el.labels[0].classList.add("tito-invalid");
          el.labels[0].classList.remove("tito-valid");
        }
      } else {
        delete el.dataset.invalid;
        el.classList.remove("tito-invalid");
        el.classList.add("tito-valid");
        if (el.labels && el.labels[0]) {
          el.labels[0].classList.remove("tito-invalid");
          el.labels[0].classList.add("tito-valid");
        }
      }
    };

    el.dataset.validation = true;
    delete el.dataset.invalid;
    el.addEventListener("focus", (e) => {
      if (isEmpty(el.value)) {
        el.classList.remove("tito-invalid");
        el.classList.remove("tito-valid");
        if (el.labels && el.labels[0]) {
          el.labels[0].classList.remove("tito-invalid");
          el.labels[0].classList.remove("tito-valid");
        }
      }
    });
    el.addEventListener(
      "validate",
      (e) => {
        validate({ allowBlank: false });
      },
      true
    );
    el.addEventListener(
      "blur",
      (e) => {
        validate({ allowBlank: true });
      },
      true
    );
  },
};
