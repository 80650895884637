import externalRouter from "js/src/config/externalRouter.js";
import Vue from "vue";

import routeIfNotCurrent from "js/src/mixins/routeIfNotCurrent.js";

import store from "../store";

export default new Vue({
  router: externalRouter,
  store,

  mixins: [routeIfNotCurrent],

  watch: {
    "$route.hash": {
      handler() {
        if (this.$route.hash.startsWith("#/tito")) {
          const query = { ...this.$route.query };
          query["tito"] = this.$route.hash.replace("#/tito", "");

          this.routeIfNotCurrent({
            query: query,
          });
        }
      },
      immediate: true,
    },
  },

  created() {
    // Store query parameters from URL query string, Vue route params, Vue route query params.
    let query = {}

    if(window.location.search) {
      (new URLSearchParams(window.location.search)).forEach((value, key) => {
        if(value && value != "tito") {
          query[key] = value;
        }
      });
    }

    if (this.$route.params) {
      for(const [key, value] of Object.entries(this.$route.params)) {
        if(value) {
          query[key] = value;
        }
      }
    }

    if (this.$route.query.tito) {
      const route = this.$router.resolve(this.$route.query.tito).route;
      if(route.query) {
        for(const [key, value] of Object.entries(route.query)) {
          if(value) {
            query[key] = value;
          }
        }
      }
    }

    if (Object.keys(query).length > 0) {
      this.$store.commit("setInitialQuery", query);
    }
  },
});
