import Vue from "vue";
import marked from "marked";

Vue.filter("markdown", function (value) {
  if (!value) return "";

  const renderer = new marked.Renderer();
  const linkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a target="_blank" ');
  };

  return marked(value, { renderer: renderer });
});
