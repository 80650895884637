<template>
  <div class="tito-quantity">
    <template>
      <span
        class="tito-release--decrement-quantity"
        v-if="release.max_tickets_per_person != 1"
      >
        <a
          :class="{
            'tito-release--decrement-quantity--link': true,
            'tito-release--decrement-quantity--link--disabled': !!!quantity,
          }"
          href="#"
          @click.prevent="decrement"
        >
          <span class="sr-only">{{ $t("decorators.release.decrease_quantity") }}</span>
          <font-awesome-icon :icon="['fas', 'minus-circle']"
        /></a>
      </span>
      <input
        ref="input"
        v-if="release.max_tickets_per_person != 1"
        :min="this.release.min_tickets_per_person || 1"
        :max="this.release.max_tickets_per_person || 100"
        class="tito-quantity-input"
        type="number"
        :disabled="disabled"
        :id="inputId"
        :name="inputName"
        :placeholder="0"
        v-model="quantity"
      />
      <span
        class="tito-release--increment-quantity"
        v-if="release.max_tickets_per_person != 1"
      >
        <a
          class="tito-release--increment-quantity--link"
          href="#"
          @click.prevent="increment"
        >
          <span class="sr-only">{{ $t("decorators.release.increase_quantity") }}</span>
          <font-awesome-icon :icon="['fas', 'plus-circle']"
        /></a>
      </span>
      <input
        v-if="release.max_tickets_per_person == 1"
        type="checkbox"
        :id="inputId"
        :name="inputName"
        class="tito-release--quantity-input"
        autocomplete="off"
        v-model="quantity"
        value="1"
        :disabled="disabled"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: ["disabled", "release", "inputId", "inputName", "solo", "value"],
  data() {
    let initialQuantityValue = this.value;
    return {
      initialQuantityValue: initialQuantityValue,
      quantity: this.value,
      selected: false,
    };
  },
  watch: {
    quantity() {
      if (typeof this.quantity === "string") {
        if (this.quantity) {
          this.quantity = parseInt(this.quantity);
        } else {
          this.quantity = null;
        }
      } else {
        this.emitQuantity();
      }
    },
  },
  methods: {
    decrement() {
      if (parseInt(this.quantity) > 1) {
        this.quantity = parseInt(this.quantity) - 1;
      } else {
        this.quantity = null;
        this.selected = false;
      }
    },

    emitQuantity() {
      this.$emit("input", this.quantity);
    },

    increment() {
      if (this.quantity && parseInt(this.quantity) === this.max_quantity) {
        return;
      }
      if (parseInt(this.quantity) >= 1) {
        return (this.quantity = parseInt(this.quantity) + 1);
      } else {
        return (this.quantity = 1);
      }
    },

    reset() {
      this.selected = false;
      this.quantity = this.initialQuantityValue;
      this.$emit("input", this.quantity);
    },
  },
};
</script>
