import Vue from "vue";
export const mutations = {
  addConfiguration(state, payload) {
    state.configuration[payload.widgetId] = payload.configuration;
  },

  setupStarted(state) {
    state.setupStarted = true;
  },

  setActiveWidgetId(state, activeWidgetId) {
    state.activeWidgetId = activeWidgetId;
  },

  setInitialQuery(state, query) {
    state.initialQuery = query;
  },

  setClosingDisabled(state, value) {
    state.closingDisabled = value;
  },

  setConfiguration(state, configuration) {
    state.configuration = configuration;
  },

  setPaymentProviderLoaded(state, provider) {
    Vue.set(state.loadedPaymentProviders, provider, true);
  },
};
