<template>
  <div class="tito-widget">
    <form class="tito-widget-form" @submit.prevent="submit" v-if="titoEvent">
      <div
        v-if="displayReleases.length > 0 && !titoEvent.registration_unavailable"
      >
        <tito-release
          ref="release"
          :discount-code="discountCodeToApply"
          :release="release"
          :account-id="accountId"
          :event-id="eventId"
          :event="titoEvent"
          :locale="localeToUse"
          v-for="release in displayReleases"
          :key="release.id"
          :solo="displayReleases.length == 1"
          :disabled="submitting"
          v-model="quantities[release.id]"
        ></tito-release>
        <locked-warning-message
          v-if="anyReleasesLocked"
          :locale="localeToUse"
        />
        <div
          class="tito-form-actions"
          :class="{
            'tito-form-actions--with-discount':
              titoEvent.settings && titoEvent.settings.show_discount_code_field,
          }"
          v-if="anyReleasesAllocatable"
        >
          <div
            :class="{
              'tito-discount': true,
              'tito-discount--applying': !readyToSubmit && !discountApplied,
            }"
            v-if="showDiscountCodeField"
          >
            <input
              type="text"
              :class="{
                'tito-discount-code-field': true,
                'tito-invalid': !!discountCodeWarning,
              }"
              @blur="applyDiscount"
              :disabled="discountCodeApplying"
              :placeholder="
                $t(
                  'views.registrations._discount_code_field.discount_code_placeholder',
                  this.localeToUse
                )
              "
              v-model="discountCodeToApply"
            />

            <span v-if="discountCodeWarning" class="tito-discount--warning">{{
              discountCodeWarning
            }}</span>
          </div>

          <button
            v-if="readyToSubmit"
            class="tito-submit"
            type="submit"
            :disabled="disabled"
          >
            <template v-if="submitting">
              <font-awesome-icon :icon="['far', 'spinner']" spin />
            </template>
            <template v-else>
              {{
                translate(titoEvent, "register_button_label", {
                  skipDefault: true,
                }) ||
                $t(
                  "decorators.registration.continue_button_label.continue",
                  locale
                )
              }}
            </template>
          </button>
          <button
            v-else-if="!discountApplied"
            class="tito-submit tito-submit--discount-code"
            type="submit"
            :disabled="disabled"
          >
            <template v-if="discountCodeApplying">
              <font-awesome-icon :icon="['far', 'spinner']" spin />
            </template>
            <template v-else>
              {{
                $t(
                  "views.registrations._discount_code_field.discount_code_submit_label",
                  localeToUse
                )
              }}
            </template>
          </button>
        </div>
      </div>
      <div
        class="tito-widget-registration-unavailable"
        v-html="translate(titoEvent, 'registration_unavailable_message')"
        v-else
      ></div>
    </form>
    <div class="tito-widget-loading" v-else>
      <font-awesome-icon :icon="['far', 'spinner']" spin />
    </div>
  </div>
</template>

<script>
import LockedWarningMessage from "./LockedWarningMessage.vue";
import TitoRelease from "./Release.vue";
import base from "js/src/mixins/base.js";
import translate from "js/src/mixins/translate.js";
import Vue from "vue";

import { i18n, loadLanguageAsync } from "js/src/config/i18n.js";

export default {
  mixins: [base, translate],
  name: "InlineWidget",
  components: { LockedWarningMessage, TitoRelease },
  data() {
    return {
      discountCodeToApply: this.discountCode,
      discountCodeApplying: false,
      discountApplied: true,
      errors: {},
      titoEvent: null,
      quantities: {},
      submitting: false,
    };
  },

  watch: {
    discountCodeToApply() {
      if (this.discountCode != this.discountCodeToApply) {
        this.discountApplied = false;
      }
    },
    locale() {
      if (this.locale) {
        loadLanguageAsync(this.locale);
      }
    },
    titoEvent() {
      Vue.nextTick(() => {
        this.sendIframeHeight();
      });
    },
  },

  mounted() {
    this.sendIframeHeight();
  },

  computed: {
    allocatableReleases() {
      return this.displayReleases.filter(function (release) {
        return release.display_state == "available";
      });
    },

    anyReleasesAllocatable() {
      return this.allocatableReleases.length > 0;
    },

    anyReleasesLocked() {
      return this.titoEvent.releases.some(
        (release) => release.display_state == "locked"
      );
    },

    disabled() {
      if (this.submitting) {
        return true;
      }
      return false;
    },

    discountCodeWarning() {
      if (
        this.discountApplied &&
        this.titoEvent &&
        this.titoEvent.discount_codes
      ) {
        let discountCode =
          this.titoEvent.discount_codes[this.discountCodeToApply.toLowerCase()];
        if (discountCode && discountCode.warnings)
          return this.translate(discountCode, "warnings").join(" ");
      }
    },

    displayReleases() {
      if (this.releases) {
        return this.titoEvent.releases.filter((release) => {
          return this.releases.indexOf(release.slug) > -1;
        });
      }
      return this.titoEvent.releases;
    },

    localeToUse() {
      if (this.locale) {
        return this.locale;
      }
      return this.titoEvent.default_locale;
    },

    params() {
      return {
        accountId: this.accountId,
        eventId: this.eventId,
        locale: this.localeToUse,
        registration: {
          client: "tito-widget-v2",
          line_items: this.selected,
          discount_code: this.discountCodeToApply,
          locale: this.locale,
          release_invitation_id: this.rsvp,
          source: this.source,
          prefill: JSON.stringify(this.mergedPrefill),
        },
      };
    },

    readyToSubmit() {
      if (!this.discountApplied) {
        return false;
      }
      return true;
    },

    selectAtLeastOneItemError() {
      return this.$t(
        "models.allocation.errors.at_least_one_release",
        this.localeToUse
      );
    },

    selected() {
      if (!this.titoEvent || !this.titoEvent.releases) {
        return [];
      }
      return this.titoEvent.releases
        .filter((release) => {
          let selection = this.quantities[release.id];
          return !!selection && !!selection.quantity;
        })
        .map((release) => {
          let selection = this.quantities[release.id];
          if (typeof selection.quantity == "boolean") {
            selection.quantity = 1;
          }
          return selection;
        });
    },

    showDiscountCodeField() {
      return (
        (this.titoEvent.settings &&
          this.titoEvent.settings.show_discount_code_field) ||
        this.allocatableReleases.some(
          (release) => release.show_discount_code_field
        )
      );
    },
  },

  created() {
    console.groupCollapsed(`[Tito] Found widget`);
    console.log(`Connecting to ${this.event}`);
    this.setPrefill();
    this.addIframeResponseListener();
  },

  methods: {
    addIframeResponseListener() {
      if (this.insideIframeId) {
        window.addEventListener("message", (event) => {
          if (
            event.data.method &&
            event.data.method == "handleSubmitResponse"
          ) {
            this.handleSubmitResponse(event.data.response);
          }
        });
      }
    },

    applyDiscount() {
      this.discountCodeApplying = true;
      if (this.discountCodeToApply) {
        this.discountCodeToApply = this.discountCodeToApply.trim();
      }
      this.get();
    },

    setPrefill() {
      if (this.mergedPrefill && Object.keys(this.mergedPrefill).length > 0) {
        console.groupCollapsed("Prefilling");
        const hash = this.mergedPrefill;
        for (let key in hash) {
          if (key !== "tickets") {
            if (this.isValidPrefill(key)) {
              console.log(`${key} = `, hash[key]);
            } else {
              console.warn(`${key} = `, hash[key], "[NOT ACCEPTED]");
            }
          }
        }
        if (hash.tickets) {
          const ticketValidator = this.isValidTicketPrefill;
          if (Array.isArray(hash.tickets)) {
            hash.tickets.forEach(function (ticket, index) {
              console.log(`Ticket #${index + 1}`);
              let foundSomething = false;
              for (let key in ticket) {
                if (ticketValidator(key)) {
                  console.log(`> ${key} = `, ticket[key]);
                } else {
                  console.warn(`${key} = `, ticket[key], "[NOT ACCEPTED]");
                }
                foundSomething = true;
              }
              if (!foundSomething) {
                console.warn(`${ticket} is unexpected`);
              }
            });
          } else {
            console.warn("tickets = ", hash.tickets, "[MUST BE AN ARRAY]");
          }
        }
        console.groupEnd();
      }
      console.groupEnd();
    },

    submit() {
      if (this.discountApplied) {
        this.submitForm();
      } else {
        this.applyDiscount();
      }
    },

    isValidPrefill(key) {
      return [
        "name",
        "email",
        "company_name",
        "vat_number",
        "phone_number",
        "address",
        "city",
        "state_province_region",
        "zip_postal_code",
        "country",
        "metadata",
      ].includes(key);
    },

    isValidTicketPrefill(key) {
      return [
        "release_slug",
        "email",
        "first_name",
        "last_name",
        "answers",
      ].includes(key);
    },

    sendIframeHeight() {
      if (!this.insideIframeId) {
        return;
      }
      const doc = document;
      // stackoverflow.com/questions/1145850/
      const body = doc.body,
        html = doc.documentElement;
      const height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      window.parent.postMessage(
        { height: height, uid: this.insideIframeId },
        "*"
      );
    },

    handleSubmitResponse(response) {
      this.submitting = false;
      if (response.errors && response.errors["base"]) {
        alert(response.errors["base"]);
      } else {
        this.$refs.release.forEach((release) => {
          release.reset();
        });
      }
    },

    processOrPost(action, params, callback) {
      if (this.insideIframeId) {
        window.parent.postMessage(
          {
            action: action,
            params: params,
            uid: this.insideIframeId,
          },
          "*"
        );
      } else {
        tito(action, params, callback);
      }
    },

    async submitForm() {
      if (!this.submitting) {
        this.submitting = true;
        if (this.selected.length > 0) {
          if (this.$parent.widgetId != null)
            this.$store.commit("setActiveWidgetId", this.$parent.widgetId);

          this.processOrPost(
            "registration.create",
            this.params,
            this.handleSubmitResponse
          );
        } else {
          alert(this.selectAtLeastOneItemError);
          this.submitting = false;
        }
      }
    },
  },
};
</script>
