import Tito from "js/src/client/client.js";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    all: {},
    loading: {},
    loaded: {},
  },
  mutations: {
    setEvent(state, payload) {
      Vue.set(state.all, payload.path, payload.event);
    },
    setLoading(state, path) {
      Vue.set(state.loading, path, true);
    },
    setLoaded(state, path) {
      Vue.set(state.loading, path, false);
      Vue.set(state.loaded, path, true);
    },
  },
  getters: {
    find: (state) => (path) => {
      return state.all[path];
    },
  },
  actions: {
    async get({ commit, state, dispatch }, payload) {
      let path = payload.path;
      let discountCodes = payload.discountCodes;
      let releases = payload.releases;
      let params = {};
      if (discountCodes) {
        params.discount_codes = discountCodes;
      }
      if (releases) {
        params.release_slugs = [];
        payload.releases
          .split(",")
          .filter(String)
          .forEach((slug) => {
            params.release_slugs.push(slug);
          });
      }
      if (payload.rsvp) {
        params.release_invitation_id = payload.rsvp
      }
      if (payload.source) {
        params.source = payload.source;
      }
      if (!state.loading[path]) {
        commit("setLoading", path);
        let event = await Tito.Event.get(path, params);
        commit("setEvent", { path: path, event: event });
        commit("setLoaded", path);
        event
          .loadCreditCardPaymentProvider()
          .then((gatewayType) => {
            console.info("[Tito]", `Loaded ${gatewayType}`);
            commit("setPaymentProviderLoaded", gatewayType, {
              root: true,
            });
          })
          .catch((e) => {
            if (/^Skip:/.test(e)) {
              // Ignore
            } else {
              console.error("[Tito]", e);
            }
          });
        event
          .loadPaypalPaymentProvider()
          .then((gatewayType) => {
            commit("setPaymentProviderLoaded", gatewayType, {
              root: true,
            });
          })
          .catch((e) => {
            if (/^Skip:/.test(e)) {
              // Ignore
            } else {
              console.error("[Tito]", e);
            }
          });
        if (tito.notifiers && tito.notifiers.event[path]) {
          tito.notifiers.event[path].forEach((notifier) => {
            notifier(event);
          });
          tito.notifiers.event[path] = [];
        }
        return event;
      }
    },
  },
};
