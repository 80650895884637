const callbacks = {
  "registration:started": [],
  "registration:terms_accepted": [],
  "registration:finished": [],
  "widget:loaded": [],
};

const on = function (item) {
  callbacks[item[0].substr(3)].push(item[1]);
};

const fire = function (event, data) {
  if (window.tito.config.iframe) {
    window.parent.postMessage({ on: `tito:fire:${event}`, data: data }, "*");
  } else {
    if (!callbacks[event]) {
      return;
    }
    if (callbacks[event].length > 0) {
      callbacks[event].forEach((callback) => {
        callback(data);
      });
    }
  }
};

window.addEventListener(
  "message",
  function (event) {
    if (event.data.on && event.data.on.substr(0, 9) === "tito:fire") {
      fire(event.data.on.replace("tito:fire:", ""), event.data.data);
    }
  },
  false
);

export default { callbacks, on, fire };
