import http from "./http.js";
import Base from "./base.js";
export default class Ticket extends Base {
  static get className() {
    return "Ticket";
  }

  async chooseUpgrade(params) {
    const response = await http.post(
      `${this.itemPath}/upgrade_choices`,
      params
    );
    return new this.constructor(response.data.ticket);
  }

  async getAvailableUpgrades() {
    const response = await http.get(`${this.itemPath}/available_upgrades.json`);
    return response.data.upgrades;
  }

  async reassign(params) {
    const response = await http.post(
      `/tickets/${this.identifier}/reassignments`,
      params
    );
    return response.data.reassignment;
  }

  async snooze() {
    await http.post(`/tickets/${this.identifier}/snoozes`);
    return true;
  }
}
