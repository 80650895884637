import eventHandling from "js/src/tito/eventHandling.js";
import flatMap from "lodash/flatMap";
import Tito from "js/src/client/client.js";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    all: {},
  },

  mutations: {
    setProcessing(state, registration) {
      state.all[registration.slug].state = "processing";
    },
    setReady(state, registration) {
      Vue.set(state.all[registration.slug], "state", "ready");
    },
    save(state, registration) {
      if (state.all[registration.slug]) {
        const wasFinished = state.all[registration.slug].finished;
        if (!wasFinished && registration.finished) {
          eventHandling.fire(
            "registration:finished",
            registration.$state.attributes
          );
        }
      }
      Vue.set(state.all, registration.slug, registration);
    },
    updateTaxBreakdown(state, payload) {
      Vue.set(
        state.all,
        payload.slug,
        Object.assign(state.all[payload.slug], payload.details)
      );
    },
  },

  getters: {
    find: (state) => (slug) => {
      return state.all[slug];
    },

    firstStartedTicket: (state, getters) => (slug) => {
      if (getters["startedTickets"](slug)[0]) {
        return new Tito.Ticket(getters["startedTickets"](slug)[0]);
      }
    },

    tickets: (state, getters) => (slug) => {
      let registration = getters["find"](slug);
      if (!registration.releases) {
        return [];
      }
      return flatMap(registration.releases, (release) => {
        return release.tickets.map((ticket) => {
          let assign = Object.assign({}, release);
          delete assign.tickets;
          ticket.release = assign;
          return ticket;
        });
      });
    },

    startedTickets: (state, getters) => (slug) => {
      return getters["tickets"](slug).filter((ticket) => {
        // The 'started' state is being removed. Check ticket is started using old and new way temporarily because we can't deploy :dashboard and :js simultaneously.
        return ticket.state === "started" || (ticket.assigned === false && ticket.snoozed === false);
      });
    },
  },

  actions: {
    async acceptTerms({ commit }, registration) {
      const updated = await registration.acceptTerms();
      eventHandling.fire("registration:terms_accepted");
      commit("save", updated);
    },

    async create({ commit }, params) {
      const registration = await Tito.Registration.create(
        Object.assign(params.registration, {
          event: `${params.accountId}/${params.eventId}`,
        })
      );
      commit("save", registration);
      return registration;
    },

    async updateSummary({ commit }, payload) {
      let response = await payload.registration.updateSummary({
        country: payload.country,
        vatNumber: payload.vat_number,
      });

      if (Object.keys(response.errors).length > 0) {
        alert(JSON.stringify(response.errors));
      } else {
        console.log("response is", response, "slug is", response.slug);
        commit("save", response);
      }
    },

    async get({ commit }, registrationId) {
      let registration = await Tito.Registration.get(registrationId);
      commit("save", registration);
      return registration;
    },
  },
};
