<template>
  <button class="tito-widget-button" @click="open">
    <slot>{{ buttonLabelOrDefault }}</slot>
  </button>
</template>

<script>
import base from "js/src/mixins/base.js";
import prefillMerge from "js/src/mixins/prefillMerge.js";
import routeIfNotCurrent from "js/src/mixins/routeIfNotCurrent.js";

export default {
  mixins: [base, prefillMerge, routeIfNotCurrent],
  name: "TitoButton",

  computed: {
    buttonLabelOrDefault() {
      return (
        this.buttonLabel ||
        this.$t("decorators.event.tickets_form_label_f", this.locale)
      );
    },
  },

  methods: {
    open() {
      this.$store.commit("setActiveWidgetId", this.widgetId);

      this.routeIfNotCurrent({
        name: "newRegistration",
        params: {
          accountId: this.accountId,
          eventId: this.eventId,
          locale: this.locale || "en",
        },
        query: {
          releases: this.releases,
          discount_code: this.discountCode,
          prefill: JSON.stringify(this.calculatedMergedPrefill),
          source: this.source,
        },
      });
    },
  },
};
</script>
