import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const TitoOverlay = () => import("js/src/components/overlay/TitoOverlay.vue");
const RegistrationsShow = () => import("js/src/components/registrations/Show.vue");

const RegistrationsNew = () => import("js/src/components/registrations/New.vue");
const RegistrationAcceptedTerms = () =>
  import("js/src/components/registrations/AcceptedTerms.vue");

const SurveysShow = () => import("js/src/components/surveys/Show.vue");
const TicketsShow = () => import("js/src/components/tickets/Show.vue");
const TicketForm = () => import("js/src/components/tickets/Form.vue");
const TicketReassign = () => import("js/src/components/tickets/Reassign.vue");

const WaitlistedPeopleNew = () =>
  import("js/src/components/waitlisted_people/New.vue");

const routes = [
  {
    name: "tito",
    path: "/",
    component: TitoOverlay,
    children: [
      {
        name: "newWaitlistedPerson",
        path: ":accountId/:eventId/:locale/releases/:releaseId/waitlisted_people/new",
        props: true,
        component: WaitlistedPeopleNew,
      },
      {
        name: "newRegistration",
        path: ":accountId/:eventId/:locale/registrations/new",
        props: true,
        component: RegistrationsNew,
      },
      {
        name: "ticket",
        path: ":accountId/:eventId/:locale?/tickets/:ticketId",
        props: true,
        component: TicketsShow,
        children: [
          {
            name: "ticketEdit",
            path: "edit",
            props: true,
            component: TicketForm,
          },
          {
            name: "ticketReassign",
            path: "reassign",
            props: true,
            component: TicketReassign,
          },
        ],
      },
      {
        name: "registration",
        path: ":accountId/:eventId/:locale?/registrations/:registrationId",
        props: true,
        component: RegistrationsShow,
        children: [
          {
            name: "registrationTicket",
            path: "tickets/:ticketId",
            props: true,
            component: TicketsShow,
            children: [
              {
                name: "registrationTicketEdit",
                path: "edit",
                props: true,
                component: TicketForm,
              },
              {
                name: "registrationTicketReassign",
                path: "reassign",
                props: true,
                component: TicketReassign,
              },
            ],
          },
          {
            name: "registrationTerms",
            path: "terms",
            props: true,
            component: RegistrationAcceptedTerms,
          },
        ],
      },
      {
        name: "survey",
        path: ":locale/surveys/:surveyId",
        props: true,
        component: SurveysShow,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "abstract", // for not changing the host’s URL
  // mode: tito.config.router.mode, // for actually changing the URL
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  let allAllowedEvents = [];
  if(tito.config) {
    if(Array.isArray(tito.config.allowedEvents)) {
      allAllowedEvents = [...new Set([...allAllowedEvents, ...tito.config.allowedEvents])];
    }
    if(Array.isArray(tito.config.autoAllowedEvents)) {
      allAllowedEvents = [...new Set([...allAllowedEvents, ...tito.config.autoAllowedEvents])];
    }
  }

  if (["registration", "newRegistration", "newWaitlistedPerson", "ticket"].includes(to.name) && allAllowedEvents.length > 0) {
    let allowedRoute = false;

    allAllowedEvents.forEach(allowedEvent => {
      if(to.path.startsWith(`/${allowedEvent}/`) || decodeURI(to.path).startsWith(`/${allowedEvent}/`)) {
        allowedRoute = true;
      }
    });

    if(!allowedRoute) {
      console.warn(
        to.path,
        "is not an allowed route on this page"
      );
      return false;
    }
  }

  next();
});

router.afterEach((to, from) => {
  if (tito.config && tito.config.iframe && tito.config.overlay) {
    window.parent.postMessage(
      {
        action: "route",
        route: to.path,
      },
      "*"
    );
  }
});

export default router;
