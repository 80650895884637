import http from "./http.js";
import Base from "./base.js";

const prefillMapping = {
  name: "name",
  email: "email",
  company_name: "company_name",
  vat_number: "vat_number",
  phone_number: "phone_number",
  address: "billing_address.address",
  city: "billing_address.city",
  state_province_region: "billing_address.state_province_region",
  zip_postal_code: "billing_address.zip_postal_code",
  country: "billing_address.country",
  purchase_order_number: "billing_address.purchase_order_number"
};
import validCountries from "./validCountries.js";

export default class Registration extends Base {
  static get className() {
    return "Registration";
  }

  async acceptTerms() {
    const response = await http.patch(
      `/registrations/${this.identifier}/terms_acceptance`
    );
    return new this.constructor(response.data.registration);
  }

  async cancel() {
    return await http.post(`/registrations/${this.identifier}/cancellation`);
  }

  async chooseUpgrades(params) {
    const response = await http.post(
      `${this.itemPath}/upgrade_choices`,
      params
    );
    return new this.constructor(response.data.registration);
  }

  prefillValues(prefill) {
    if (!this.$state.attributes.billing_address) {
      this.$state.attributes.billing_address = {};
    }

    let goAhead = true;
    let warnings = false;
    for (let [key, value] of Object.entries(prefill)) {
      if (prefillMapping[key]) {
        let target = this.$state.attributes;
        const mappedKeys = prefillMapping[key].split(".");
        let keyToUse = mappedKeys[0];
        if (mappedKeys.length == 2) {
          target = target[mappedKeys[0]];
          keyToUse = mappedKeys[1];
        }
        if (keyToUse === "country" && !validCountries.includes(value)) {
          warnings = true;
        }
        if (target[keyToUse]) {
          goAhead = false;
        }
      } else {
        warnings = true;
      }
    }

    if (!goAhead) {
      return true;
    }

    const logList = [];

    for (let [key, value] of Object.entries(prefill)) {
      if (prefillMapping[key]) {
        let target = this.$state.attributes;
        const mappedKeys = prefillMapping[key].split(".");
        let keyToUse = mappedKeys[0];
        if (mappedKeys.length == 2) {
          target = target[mappedKeys[0]];
          keyToUse = mappedKeys[1];
        }
        target[keyToUse] = value;

        if (keyToUse === "country" && !validCountries.includes(value)) {
          logList.push(
            `${keyToUse}: '${value}' isn't a valid country value. Please use the two letter uppercase code, e.g. 'IE'.`
          );
        } else {
          logList.push(`${keyToUse}: '${value}'`);
        }
      } else {
        if (["county", "state", "province", "region"].includes(key)) {
          logList.push(
            `${key} is not prefillable. Did you mean 'state_province_region'?`
          );
        } else if (
          [
            "zip",
            "zip_code",
            "zipcode",
            "postal_code",
            "post_code",
            "postcode"
          ].includes(key)
        ) {
          logList.push(
            `${key} is not prefillable. Did you mean 'zip_postal_code'?`
          );
        } else {
          logList.push(`${key} is not prefillable`);
        }
      }
    }

    if (logList.length) {
      if (warnings) {
        console.group("[Tito] Prefilling");
      } else {
        console.groupCollapsed("[Tito] Prefilling");
      }
      for (const message of logList) {
        console.log(message);
      }
      console.groupEnd();
    }
  }

  async fulfill() {
    return await http.post(`/registrations/${this.identifier}/fulfillment`);
  }

  async createPayment(attributes) {
    const response = await http.post(`${this.itemPath}/payment`, attributes);
    return response.data.payment;
  }

  async getAvailableUpgrades() {
    const response = await http.get(`${this.itemPath}/available_upgrades.json`);
    return response.data.upgrades;
  }

  async getTermsets() {
    const response = await http.get(`${this.itemPath}/termsets.json`);
    return response.data.termsets;
  }

  async pay() {
    const response = await http.patch(`${this.itemPath}/payment`);
    return response.data.payment;
  }

  async updateSummary(params) {
    const response = await http.patch(`${this.itemPath}/summary`, {
      registration: {
        billing_address_attributes: {
          country: params.country,
          vat_number: params.vatNumber
        }
      }
    });
    const body = response.data;
    if (body.errors) {
      return { errors: body.errors };
    } else {
      this.$state.attributes = Object.assign(this.$state.attributes, body);
      return new this.constructor(this.$state.attributes);
    }
  }
}
