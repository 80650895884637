import http from "./http.js";

import Registration from "./registration.js";
import Ticket from "./ticket.js";
import TitoEvent from "./TitoEvent.js";

export default new class {
  constructor(Event, Ticket, Registration, http) {
    this.Event = Event;
    this.Ticket = Ticket;
    this.Registration = Registration;
    this.http = http;
  }

  set api_key(val) {
    if (val) {
      this.http.defaults.headers["Authorization"] = `Token token=${val}`;
    } else {
      this.http.defaults.headers["Authorization"] = null;
    }
  }

  set baseURL(val) {
    this.http.defaults.baseURL = val;
  }

}(TitoEvent, Ticket, Registration, http);
