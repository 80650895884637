import http from "./http.js";
import snakeCase from "lodash/snakeCase";

const wrapParams = function(className, params) {
  const key = snakeCase(className);
  if (params[key]) {
    throw `Don't wrap the params inside a "${key}" attribute`;
  }
  return {
    [key]: params
  };
};

export default class Base {
  constructor(attributes = {}) {
    this.$state = {
      attributes: attributes || {},
      errors: {}
    };
    if (attributes.prefill && this.prefillValues) {
      this.prefillValues(attributes.prefill);
    }
    this.defineGettersAndSetters();
  }
  get identifier() {
    return this.$state.attributes.slug || this.$state.attributes.id;
  }
  get errors() {
    return this.$state.errors;
  }
  set errors(value) {
    this.$state.errors = value;
  }
  defineGettersAndSetters() {
    for (let [attribute, value] of Object.entries(
      this.$state.attributes || {}
    )) {
      this.$state.attributes[attribute] = value;
      if (!this.hasOwnProperty(attribute)) {
        Object.defineProperty(this, attribute, {
          get() {
            return this.$state.attributes[attribute];
          },

          set(value) {
            this.$state.attributes[attribute] = value;
          }
        });
      }
    }
  }
  save() {
    if (this.valid()) {
      return true;
    }
    return false;
  }
  valid() {
    this.errors = {};
    for (let [attribute, options] of Object.entries(
      this.constructor.validations
    )) {
      if (options.presence && !this.$state.attributes[attribute]) {
        this.$state.errors[attribute] = this.$state.errors[attribute] || [];
        this.$state.errors[attribute].push("is required");
      }
    }
    if (Object.keys(this.errors).length > 0) {
      return false;
    }
    return true;
  }

  async update(attributes) {
    const response = await http.patch(
      this.constructor.itemPath(this.identifier),
      wrapParams(this.constructor.className, attributes)
    );
    return new this.constructor(
      response.data[snakeCase(this.constructor.className)]
    );
  }

  static async create(attributes) {
    const path = `/${attributes.event}${this.collectionPath}`;
    delete attributes.event;
    console.log("[Tito]", "creating with params", wrapParams(this.className, attributes));
    const response = await http.post(
      path,
      wrapParams(this.className, attributes)
    );
    const jsonName = snakeCase(this.className);
    const responseAttributes = response.data[jsonName];
    if (responseAttributes) {
      return new this(responseAttributes);
    } else {
      throw `Response did not include attribute ${jsonName}`;
    }
  }

  static get collectionPath() {
    return `/${snakeCase(this.className)}s`;
  }

  get itemPath() {
    return this.constructor.itemPath(this.identifier);
  }

  static get modelName() {
    return snakeCase(this.className);
  }

  static itemPath(id) {
    return `${this.collectionPath}/${id}`;
  }

  static async get(id, params) {
    const response = await http.get(`${this.itemPath(id)}.json`, { params: params });
    const attributes = response.data[this.modelName];
    return new this(attributes);
  }
}
