export default {
  computed: {
    eventBaseUrl() {
      var host = `https://${window.tito.config.primary_host}`;
      if (this.accountId && this.eventId) {
        return `${host}/${this.accountId}/${this.eventId}`;
      } else {
        return host;
      }
    },
  },

  methods: {
    registrationUrl(slug) {
      return `${this.eventBaseUrl}/registrations/${this.slug}`;
    },

    ticketUrl(slug) {
      return `${this.eventBaseUrl}/tickets/${this.slug}`;
    },
  }
}
