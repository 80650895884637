export default function(autoAllowedEvent) {
  if(
    autoAllowedEvent &&
    window.tito &&
    window.tito.config &&
    Array.isArray(window.tito.config.autoAllowedEvents) &&
    !window.tito.config.autoAllowedEvents.includes(autoAllowedEvent)
  ) {
    window.tito.config.autoAllowedEvents.push(autoAllowedEvent);
  }
};
