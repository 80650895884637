import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const externalRouter = new VueRouter({
  mode: "history", // for not changing the host’s URL
});

externalRouter.beforeEach((to, from, next) => {
  // Allow native HTML bookmark links to work by cancelling Vue router navigation.
  if(!!window.location.hash && Object.keys(to.query).length == 0 && Object.keys(to.params).length == 0) {
    return false;
  }

  next();
});

export default externalRouter;
