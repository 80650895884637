import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faApple } from "@fortawesome/free-brands-svg-icons/faApple";
import { faApplePay } from "@fortawesome/free-brands-svg-icons/faApplePay";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faPaypal } from "@fortawesome/free-brands-svg-icons/faPaypal";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";

import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons/faCalendarAlt";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { faReceipt } from "@fortawesome/pro-light-svg-icons/faReceipt";
import { faTicketAlt } from "@fortawesome/pro-light-svg-icons/faTicketAlt";

import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons/faFileInvoice";
import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faIdCardAlt } from "@fortawesome/pro-regular-svg-icons/faIdCardAlt";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faPrint } from "@fortawesome/pro-regular-svg-icons/faPrint";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";

import { faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons/faArrowCircleRight";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faBan } from "@fortawesome/pro-solid-svg-icons/faBan";
import { faCaretLeft } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload";
import { faEnvelope as faEnvelopeSolid } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faHandPaper } from "@fortawesome/pro-solid-svg-icons/faHandPaper";
import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons/faMinusCircle";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons/faQuestion";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUniversity } from "@fortawesome/pro-solid-svg-icons/faUniversity";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";

library.add(
  faApple,
  faApplePay,
  faArrowCircleRight,
  faBan,
  faBuilding,
  faCalendarAlt,
  faCaretLeft,
  faCheck,
  faCircleNotch,
  faClock,
  faCreditCard,
  faDownload,
  faEnvelope,
  faEnvelopeSolid,
  faExclamationTriangle,
  faFacebook,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faHandPaper,
  faIdCardAlt,
  faInfoCircle,
  faMinusCircle,
  faPaypal,
  faPhone,
  faPlusCircle,
  faPrint,
  faQuestion,
  faReceipt,
  faSpinner,
  faTicketAlt,
  faTimes,
  faTwitter,
  faUniversity,
  faUser,
);

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
