<template>
  <div class="tito-widget">
    <iframe
      ref="iframe"
      :src="iframeSrc"
      style="border: 0; height: 0px; width: 100%"
      class="tito-iframe"
    ></iframe>
  </div>
</template>

<script>
import base from "js/src/mixins/base.js";

import router from "js/src/config/router.js";
import externalRouter from "js/src/config/externalRouter.js";
import vueWithExternalRouter from "js/src/config/vueWithExternalRouter.js";

export default {
  mixins: [base],
  router: externalRouter,
  name: "IframeWidget",
  mounted() {
    window.addEventListener("message", this.receiveMessage, false);
  },
  computed: {
    iframeSrc() {
      const query = { tito: this.$route.query.tito };
      query.uid = this._uid;
      if(window.tito.config.testMode) {
        query.plugins = "test_mode";
      }

      return (
        `https://${window.tito.config.js_host}/v2/iframes/widget?` +
        new URLSearchParams(query).toString()
      );
    },

    params() {
      return this.$props;
    },
  },

  methods: {
    sendSubmitResponse(response) {
      if (!this.$refs.iframe) {
        return;
      }
      this.$refs.iframe.contentWindow.postMessage(
        {
          method: "handleSubmitResponse",
          response: response,
        },
        "*"
      );
    },

    receiveMessage(event) {
      if (!event.data.uid) {
        return;
      }
      if (event.data.uid !== this._uid) {
        return;
      }
      if (event.data.state && event.data.state === "ready") {
        this.$refs.iframe.contentWindow.postMessage(
          { params: this.params },
          "*"
        );
      }
      if (this.$refs.iframe && event.data.height) {
        this.$refs.iframe.style.height = event.data.height + 4 + "px";
      }
      if (event.data.action && event.data.action === "registration.create") {
        this.$store.commit("setActiveWidgetId", this.$parent.widgetId);
        tito("registration.create", event.data.params, this.sendSubmitResponse);
      } else if (
        event.data.action &&
        event.data.action === "waitlisted_person.new"
      ) {
        tito(event.data.action, event.data.params);
      } else if (event.data.action) {
        console.log(event.data.action + " event not processed");
      }
    },
  },
};
</script>
