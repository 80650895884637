<template>
  <div id="tito-overlay" role="dialog" aria-label="Tito Widget" v-if="$route.path != '/'">
    <transition
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:leave="leave"
      v-bind:css="false"
    >
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Velocity from "velocity-animate";
import "js/src/filters/format_money.js";
import "js/src/filters/markdown.js";

import { i18n, loadLanguageAsync } from "js/src/config/i18n.js";

import router from "js/src/config/router.js";

import vueWithExternalRouter from "js/src/config/vueWithExternalRouter.js";

router.beforeEach(async (to, from, next) => {
  const locale = to.params.locale;
  if (to.params.locale) {
    await loadLanguageAsync(locale);
  }
  if (typeof next === "function") {
    next();
  }
});

import store from "../store";

import parseQueryParams from "js/lib/parseQueryParams";

import routeIfNotCurrent from "js/src/mixins/routeIfNotCurrent.js";

export default {
  router,
  store,
  i18n,

  mixins: [routeIfNotCurrent],

  mounted() {
    vueWithExternalRouter.$router.afterEach((to, from) => {
      this.handleExternalRoute();
    });
    this.handleExternalRoute();
  },

  methods: {
    beforeEnter(el) {
      Velocity(el, { opacity: 0 }, { duration: 0 });
      this.ready = true;
    },
    enter(el, done) {
      Velocity(el, { opacity: 1 }, { duration: 250, done: done });
    },
    handleExternalRoute() {
      if (window.tito.config.inline && window.tito.config.iframe) {
        this.routeIfNotCurrent(vueWithExternalRouter.$route.query.tito);
      } else {
        if (
          vueWithExternalRouter.$route.query.tito &&
          this.$route.fullPath != vueWithExternalRouter.$route.query.tito
        ) {
          const titoFullpathParts = decodeURIComponent(
            vueWithExternalRouter.$route.query.tito
          ).split("?");
          const path = titoFullpathParts[0];
          const query = parseQueryParams(titoFullpathParts[1]);
          this.routeIfNotCurrent({
            path: path,
            query: query,
          });
        }
      }
    },
    async leave(el, done) {
      await Velocity(el, { opacity: 0 }, { duration: 250 });
      done();
    },
  },
};
</script>
