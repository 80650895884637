/*
  Will try and parse some JSON but will also cope with and empty
  string or a null or undefined. If you pass it something that is
  not a string then it just passes it back.
*/
function lenientParse(text) {
  if (typeof text !== "string") {
    return text
  }
  if (text === "") {
    return {}
  }
  try {
    return JSON.parse(text);
  } catch (e) {
    if (/JSON\.parse/.test(e.message)) {
      console.error("[Tito]", "Problem parsing the JSON", text);
    } else {
      throw e;
    }
  }
}

/*
  Will merge the value into the existing data.metadata and allow for
  it already being JSON (or not).
*/
function addMetadata(data, value) {
  if (!value) return;
  let jsonValue = lenientParse(value);
  if (Object.keys(jsonValue).length) {
    data.metadata = {
      ...data.metadata,
      ...jsonValue,
    };
  }
}

export default {
  props: {
    saveMetadataParameters: {
      type: String,
    },
    prefill: {
      default() {
        return this.$route.query.prefill;
      },
    },
  },

  computed: {
    calculatedMergedPrefill() {
      if (this.mergedPrefill) {
        return this.mergedPrefill;
      }
      let data = {};
      if (this.prefill) {
        data = lenientParse(this.prefill);
        data.metadata = lenientParse(data.metadata);
      }
      addMetadata(data, this.metadataPassthroughData);
      if (this.prefillName) {
        data.name = this.prefillName;
      }
      if (this.prefillEmail) {
        data.email = this.prefillEmail;
      }
      if (this.prefillCompanyName) {
        data.company_name = this.prefillCompanyName;
      }
      if (this.prefillVatNumber) {
        data.vat_number = this.prefillVatNumber;
      }
      if (this.prefillPhoneNumber) {
        data.phone_number = this.prefillPhoneNumber;
      }
      if (this.prefillAddress) {
        data.address = this.prefillAddress;
      }
      if (this.prefillCity) {
        data.city = this.prefillCity;
      }
      if (this.prefillStateProvinceRegion) {
        data.state_province_region = this.prefillStateProvinceRegion;
      }
      if (this.prefillZipPostalCode) {
        data.zip_postal_code = this.prefillZipPostalCode;
      }
      if (this.prefillCountry) {
        data.country = this.prefillCountry;
      }
      addMetadata(data, this.prefillMetadata);
      if (data.metadata) {
        console.log("[Tito]", "Using metadata", data.metadata);
      }
      return data;
    },

    metadataPassthroughData() {
      if (this.saveMetadataParameters) {
        const data = {};
        let keys = [];
        this.saveMetadataParameters.split(",").forEach((key) => {
          if (/\*/.test(key)) {
            const pattern = new RegExp(`^${key.replace("*", ".*")}$`);
            for (const k of this.standardQueryStringParameters.keys()) {
              if (pattern.test(k)) {
                keys.push(k);
              }
            }
          } else {
            keys.push(key);
          }
        });
        console.groupCollapsed("saveMetadataParameters");
        keys.forEach((p) => {
          const v = this.standardQueryStringParameters.get(p);
          if (v) {
            data[p] = v;
            console.log(`${p}:`, v);
          }
        });
        console.groupEnd();
        return data;
      }
    },
  },
};
