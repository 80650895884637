export default {
  methods: {
    translate(model, attribute, options) {
      const locale = this.localeToUse || this.locale;
      if (
        model.translations[locale] &&
        model.translations[locale][attribute]
      ) {
        return model.translations[locale][attribute];
      }
      if (!options || !options.skipDefault) {
        return model[attribute];
      }
    },
  },
};
