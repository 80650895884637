import { uniq } from "lodash";

import autoAllowEvent from "js/src/tito/autoAllowEvent.js";
import configSet from "./processors/config-set.js";
import pluginLoadProcessor from "./processors/plugin-load.js";
import eventHandling from "./eventHandling.js";

const processors = {
  "button.mount": "button-mount",
  "event.get": "event-get",
  "plugin.load": "plugin-load",
  "registration.create": "registration-create",
  "widget.mount": "widget-mount",
  "waitlisted_person.new": "waitlistedPerson-new",
};

const loadProcessor = async function (item) {
  if (item[0] == "config.set") {
    configSet(item);
  }

  const processorName = processors[item[0]];
  if (processorName) {
    if (processorName == "plugin-load") {
      pluginLoadProcessor.call(this, item);
    } else {
      const processor = await import(`./processors/${processorName}`);
      processor.default.call(this, item);
    }
  }
  if (
    item[0].substr(0, 3) === "on:" &&
    eventHandling.callbacks[item[0].substr(3)]
  ) {
    eventHandling.on(item);
  }
  if (item[0].substr(0, 5) === "fire:") {
    eventHandling.fire(item[0].substr(5), item[1]);
  }
};

const queue = { items: [] };
if (window.tito && window.tito.q) {
  queue.items = window.tito.q;
}

window.tito = function () {
  window.tito.notifiers = window.tito.notifiers || { event: {} };
  return loadProcessor(arguments);
};

window.tito.config = window.tito.config || {};
window.tito.config.queryParam = window.tito.config.queryParam || "tito";
window.tito.config.autoAllowedEvents = window.tito.config.autoAllowedEvents || [];

["tito-button", "tito-widget"].forEach(widgetTag => {
  document.querySelectorAll(widgetTag).forEach(widget => {
    autoAllowEvent(widget.getAttribute("event"));
  });
});

import filter from "lodash/filter";

const configQueue = filter(queue.items, function (item) {
  return item[0] == "config.set";
});

const workQueue = filter(queue.items, function (item) {
  return item[0] != "config.set";
});

const processQueue = function (items) {
  if (items.length > 0) {
    uniq(items).forEach((item) => {
      loadProcessor(item);
    });
  }
};

import router from "js/src/config/router.js";
import externalRouter from "js/src/config/externalRouter.js";

import vueWithInternalRouter from "js/src/config/vueWithInternalRouter.js";
import vueWithExternalRouter from "js/src/config/vueWithExternalRouter.js";

router.afterEach((to, from) => {
  if (to.path === "/" && Object.keys(to.query).length === 0) {
    vueWithExternalRouter.routeIfNotCurrent({
      query: null,
    });
    return;
  }
  vueWithExternalRouter.routeIfNotCurrent({
    query: {
      tito: to.fullPath,
    },
  });
});

processQueue(configQueue);
processQueue(workQueue);

const warning =
  "It looks like you have included Tito Widget V1 on your page. Please remove V1 before using V2.";

if (window.TitoWidget) {
  alert(warning);
} else {
  window.__defineSetter__("TitoWidget", function () {
    alert(warning);
  });
}
