import axios from "axios";
import Vue from "vue";
import Tito from "js/src/client/client.js";
export default {
  namespaced: true,
  state: {
    all: {},
  },

  mutations: {
    saveTicket(state, ticket) {
      Vue.set(state.all, ticket.slug, ticket);
    },
  },

  getters: {
    find: (state) => (slug) => {
      return state.all[slug];
    },
  },

  actions: {
    async get({ commit }, ticketId) {
      const ticket = await Tito.Ticket.get(ticketId);

      commit("saveTicket", ticket);
      return ticket;
    },

    async voidTicket({ commit }, ticketSlug) {
      const response = await axios.post(`https://${window.tito.config.checkout_host}/tickets/${ticketSlug}/void.json`);
      const ticket = response.data.void.ticket;

      commit("saveTicket", ticket);
      return ticket;
    },
  },
};
