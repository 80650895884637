import axios from "axios";
import Tito from "js/src/client/client.js";

export default function (params) {
  console.debug("[Tito][test_mode]", "plugin loaded");
  tito("config.set", { testMode: true });
  axios.defaults.headers.common["Tito-Test-Mode"] = true;
  Tito.http.defaults.headers.common["Tito-Test-Mode"] = true;

  // Call headers to ensure Tito-Test-Mode actually gets set
  axios.defaults.headers.common;
  Tito.http.defaults.headers.common;
}
