import Vue from "vue";
import TitoApp from "js/src/components/TitoApp.vue";

export default function () {
  if (typeof window === "object" && !document.getElementById("tito-overlay")) {
    let overlayDiv = document.createElement("div");
    overlayDiv.id = "tito-overlay";
    document.body.appendChild(overlayDiv);

    new Vue({
      el: "#tito-overlay",
      render: (h) => h(TitoApp),
    });
  }
}
