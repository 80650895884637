import Vue from "vue";
import Vuex from "vuex";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
Vue.use(Vuex);

import event from "./modules/event";
import ticket from "./modules/ticket";
import registration from "./modules/registration";

export default new Vuex.Store({
  modules: {
    event,
    ticket,
    registration,
  },
  state: {
    configuration: {},
    activeWidgetId: null,
    closingDisabled: false,
    initialQuery: {},
    setupStarted: false,
    overlayOpen: false,
    loadedPaymentProviders: {},
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
});
