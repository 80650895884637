<template>
  <a :href="registrationUrl(slug)" target="_blank" v-if="stopBecauseInsecure">View order</a>
</template>

<script>
import { i18n, loadLanguageAsync } from "js/src/config/i18n.js";
import hostedUrls from "js/src/mixins/hostedUrls.js";
import routeIfNotCurrent from "js/src/mixins/routeIfNotCurrent.js";
import sslCheck from "js/src/mixins/sslCheck.js";
import router from "js/src/config/router.js";
import store from "js/src/store/index.js";

export default {
  i18n,
  mixins: [hostedUrls, routeIfNotCurrent, sslCheck],
  name: "TitoRegistration",
  router,
  store,
  props: {
    locale: null,
    slug: null,
  },

  mounted() {
    if (this.stopBecauseInsecure) {
      return;
    }
    this.setClosingDisabled();
    this.getRegistration();
  },

  computed: {
    accountId() {
      if(this.registration && this.registration.account) {
        return this.registration.account.slug;
      }
    },

    defaultLocale() {
      if(this.registration) {
        return this.registration.locale;
      }
    },

    eventId() {
      if(this.registration && this.registration.event) {
        return this.registration.event.slug;
      }
    },

    localeOrDefault() {
      return this.locale || this.defaultLocale;
    },

    registration() {
      const storeRegistration = this.$store.getters["registration/find"](this.slug);
      if(storeRegistration && storeRegistration.$state) {
        return storeRegistration.$state.attributes;
      }
    }
  },

  methods: {
    getRegistration() {
      this.$store.dispatch("registration/get", this.slug);
    },

    setClosingDisabled() {
      this.$store.commit("setClosingDisabled", this.$el.parentElement.hasAttribute("closing-disabled"));
    }
  },

  watch: {
    localeOrDefault() {
      if(this.localeOrDefault) {
        loadLanguageAsync(this.localeOrDefault);
      }
    },

    registration() {
      this.routeIfNotCurrent({
        name: "registration",
        params: {
          accountId: this.accountId,
          eventId: this.eventId,
          registrationId: this.slug,
          locale: this.locale
        },
      });
    }
  }
};
</script>
